const subBrands = {
  Shoes: ["Adidas", "Nike", "New Balance", "Balenciaga"],
  Watch: [
    "Watch 199",
    "Watch 249",
    "Watch 299",
    "Watch 349",
    "Watch 399",
    "Watch 449",
    "Watch 499",
    "Watch 549",
    "Watch 599",
    "Watch 649",
    "Watch 699",
    "Watch 749",
    "Watch 799",
    "Watch 849",
    "Watch 899",
    "Watch 949",
    "Watch 999",
    "Watch 1049",
    "Watch 1099",
    "Watch 1149",
    "Watch 1199",
    "Watch 1249",
    "Watch 1299",
    "Watch 1349",
    "Watch 1399",
    "Watch 1449",
    "Watch 1499",
    "Watch 1549",
    "Watch 1599",
    "Watch 1649",
    "Watch 1699",
    "Watch 1749",
    "Watch 1799",
    "Watch 1849",
    "Watch 1899",
    "Watch 1949",
    "Watch 1999",
    "Combo 699",
    "Gshock 799",
    "Chain 599",
    "Vintage 199",
    "Vintage 299",
  ],
  Crocs: [
    "Crocs 899",
    "Crocs 1299",
    "Crocs 1399",
    "Crocs 1499",
    "Crocs 1599",
    "Crocs 1699",
    "Crocs 1799",
    "Crocs 1899",
    "Crocs 1999",
  ],
};

export default subBrands;

