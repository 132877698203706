import React, { useState } from "react";
import TaqwaLogoRemoved from "../../images/taqwa-removed.png";
import CircularProgress from "@mui/material/CircularProgress";
import supabase from "../../supabase";
import { Turnstile } from "@marsidev/react-turnstile";
export default function Login() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  async function signIn(event) {
    event.preventDefault();
    setLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
      options: {
        captchaToken: captchaToken,
      },
    });
    if (error) alert(error.message);
    setLoading(false);
  }

  return (
    <div className="page-login flex items-center justify-center h-screen bg-gray-400  ">
      <div
        className="cont-google text-center bg-white p-10 w-[70%] "
        style={{
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
        }}
      >
        <img src={TaqwaLogoRemoved} alt="TAQWA" className="mx-auto mb-6" />
        <span className="block logo-label text-2xl mb-2">FASHION STORE</span>
        <span className="block text-right text-[0.9em] mb-6">Admin Portal</span>
        <form onSubmit={signIn}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-3 py-2 mb-4 border rounded focus:outline-none focus:border-blue-500"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full px-3 py-2 mb-6 border rounded focus:outline-none focus:border-blue-500"
          />
          <button
            type="submit"
            className="w-full px-6 py-2 text-white bg-black rounded font-bold cursor-pointer"
          >
            {loading ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Sign In"
            )}
          </button>
          <Turnstile
            siteKey={process.env.REACT_APP_CLOUDFLARE_SITE_KEY}
            onSuccess={(token) => {
              setCaptchaToken(token);
            }}
          />
        </form>
      </div>
    </div>
  );
}
