import React from "react";
import "./404.css";

function Four0four() {
  const currentUrl = window.location.href;

  const reuestedUrl = currentUrl.split("?url=")[1];
  return (
    <>
      <div className=" selection body404 page h-screen">
        <h1 className="h1404">404</h1>
        <p className="msg404">Oops! Page not found</p>

        <a className="button404" href="/">
          <i class="icon-home"></i> Going back, is better.
        </a>
      </div>
      <div className="selection w-full m-auto">
        <span className="text-center text-sm m-auto  absolute bottom-3 text-white left-2 ">
          You requested URL: <strong>{reuestedUrl}</strong>
        </span>
      </div>
    </>
  );
}

export default Four0four;
