import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import supabase from "../../supabase";
import TopBar from "../../components/TopBar";
import CircularProgress from "@mui/material/CircularProgress";
// import { IKContext, IKUpload } from "imagekitio-react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import subBrands from "../../components/staticSubCats";
import imageCompression from "browser-image-compression";

// const authenticator = async () => {
//   console.log(session);
//   if (!session) {
//     throw new Error("No session found");
//   }
//   try {
//     const response = await fetch(process.env.REACT_APP_IMAGEKIT_AUTH_URL, {
//       headers: {
//         Authorization: `Bearer ${session.access_token}`,
//       },
//     });

//     if (!response.ok) {
//       const errorText = await response.text();
//       throw new Error(
//         `Request failed with status ${response.status}: ${errorText}`
//       );
//     }

//     const data = await response.json();
//     const { signature, expire, token } = data;
//     return { signature, expire, token };
//   } catch (error) {
//     throw new Error(`Authentication request failed: ${error.message}`);
//   }
// };

export default function ProductEdit() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const ikUploadRefTest = useRef(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [priceMap, setPriceMap] = useState({});
  const [prevMap, setPrevMap] = useState({});
  const [stockMap, setStockMap] = useState({});
  const [codPriceMap, setCodPriceMap] = useState({});
  const [category, setCategory] = useState(null);

  const handleDelete = async () => {
    const onSuccess = () => {
      // Perform some action after the product is successfully deleted
      console.log("Product deleted successfully");
    };

    const ConfirmDeleteToast = ({ closeToast }) => (
      <div>
        Are you sure you want to delete this product?
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <button
            style={{
              backgroundColor: "#4CAF50" /* Green */,
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={async () => {
              setLoading(true);
              const { error } = await supabase
                .from("products")
                .delete()
                .eq("id", productId);

              if (error) {
                setLoading(false);
                console.error("Error deleting product: ", error);
                toast.error("Error deleting product");
              } else {
                setLoading(false);
                toast.success("Product deleted successfully");
                onSuccess();
                navigate("/");
              }
              closeToast();
            }}
          >
            Confirm
          </button>
          <button
            style={{
              backgroundColor: "#f44336" /* Red */,
              border: "none",
              color: "white",
              padding: "15px 32px",
              textAlign: "center",
              textDecoration: "none",
              display: "inline-block",
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={closeToast}
          >
            Cancel
          </button>
        </div>
      </div>
    );
    toast(<ConfirmDeleteToast />, {
      position: "top-right",
      autoClose: false,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
  };

  const onProgressStart = (operation) => {
    setLoading(true);
    console.log(`Image ${operation} Started`);
    toast.info(`Image ${operation} Started`);
  };

  const onError = (operation, err) => {
    setLoading(false);
    console.error(`Error ${operation} image: `, err);
    toast.error(`Error ${operation} image: `, err);
  };

  const onSuccess = (operation, res) => {
    setLoading(false);
    toast.success(`Image ${operation} successfully`);
    console.log(`Image ${operation} successfully`, res);
    if (operation !== "Upload") {
      return;
    }
    setProduct((prevProduct) => ({
      ...prevProduct,
      images: [...prevProduct.images, res.data.file_url],
    }));
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const { data, error } = await supabase
        .from("products")
        .select("*")
        .eq("id", productId)
        .single();

      if (error) {
        console.error("Error fetching product: ", error);
        console.log(error.code);
        if (error.code === "PGRST116") {
          navigate("/404?url=" + window.location.href);
        }
      } else {
        console.log("Data", data);
        setProduct(data);
        setSelectedSizes(data.available_sizes || []); // Initialize selectedSizes with product.available_sizes
        setPriceMap(data.priceMap || {});
        setPrevMap(data.prevMap || {});
        setStockMap(data.stockMap || {});
        setCodPriceMap(data.codPriceMap || {});
      }
    };
    const fetchCategories = async () => {
      const { data, error } = await supabase.from("categories").select("*");
      if (error) {
        console.error("Error fetching categories: ", error);
      } else {
        setCategories(data);
        console.log(categories);
      }
    };

    fetchProduct();
    fetchCategories();
  }, [productId]);

  const handlePriceChange = (size, event) => {
    setPriceMap({ ...priceMap, [size]: event.target.value });
    console.log(priceMap);
  };

  const handleCodPriceChange = (size, event) => {
    setCodPriceMap({ ...codPriceMap, [size]: event.target.value });
    console.log(codPriceMap);
  };

  const handleStockChange = (size, event) => {
    setStockMap({ ...stockMap, [size]: event.target.value });
    console.log(stockMap);
  };

  const handlePrevPriceChange = (size, event) => {
    console.log("PrevMap", prevMap);
    setPrevMap({ ...prevMap, [size]: event.target.value });
    console.log(prevMap);
  };

  useEffect(() => {
    console.log("Updated product:", product);
  }, [product]);

  const handleSizeClick = (size) => {
    if (category && category.stock_map_required) {
      if (selectedSizes.includes(size)) {
        setSelectedSizes(selectedSizes.filter((s) => s !== size));
        setStockMap((prevStockMap) => {
          const { [size]: _, ...rest } = prevStockMap;
          return rest;
        });
      }
    }

    if (category && category.cod_price_map_required) {
      if (selectedSizes.includes(size)) {
        setSelectedSizes(selectedSizes.filter((s) => s !== size));
        setCodPriceMap((prevCodPriceMap) => {
          const { [size]: _, ...rest } = prevCodPriceMap;
          return rest;
        });
      }
    }

    if (category && category.price_map_required) {
      if (selectedSizes.includes(size)) {
        setSelectedSizes(selectedSizes.filter((s) => s !== size));
        setPriceMap((prevPriceMap) => {
          const { [size]: _, ...rest } = prevPriceMap;
          return rest;
        });

        setPrevMap((prevPriceMap) => {
          const { [size]: _, ...rest } = prevPriceMap;
          return rest;
        });
      }
    }
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
    } else {
      setSelectedSizes([...selectedSizes, size]);
    }
  };

  useEffect(() => {
    if (product) {
      setProduct({ ...product, available_sizes: selectedSizes });
    }
  }, [selectedSizes]);
  const handleRatingChange = (e) => {
    if (e.target.value > 5) {
      setError("Rating can't be more than 5.");
    } else {
      setError(null);
      setProduct({ ...product, avg_rating: e.target.value });
    }
  };

  const handleDeleteImage = async (index) => {
    const url = product.images[index];
    console.log(url);
    const { data, error } = await supabase.auth.getSession();
    const session = data.session;

    try {
      onProgressStart("Delete");
      const response = await fetch(
        `${process.env.REACT_APP_IMAGE_URL}/delete`,
        {
          method: "POST",
          body: JSON.stringify({
            imageURL: url,
          }),
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );

      if (!response.ok) {
        onError("Delete", response.statusText);
        throw new Error("Upload failed");
      }

      const result = await response.json();
      onSuccess("Delete", result);
      setProduct({
        ...product,
        images: product.images.filter((_, i) => i !== index),
      });
    } catch (error) {
      onError(error);
      // Handle error (e.g., show error message to user)
    }
  };

  useEffect(() => {
    if (categories && product) {
      const foundCategory = categories.find(
        (category) => category.category === product.category
      );
      setCategory(foundCategory);
    }
  }, [categories, product]);

  const handleUpload = async (imageFile) => {
    console.log("originalFile instanceof Blob", imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    console.log("File Name : ", imageFile.name);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      preserveExif: true,
    };
    try {
      const compressedBlob = await imageCompression(imageFile, options);
      console.log(
        "compressedFile instanceof Blob",
        compressedBlob instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedBlob.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      const compressedFile = new File([compressedBlob], imageFile.name, {
        type: compressedBlob.type,
      });
      await uploadToServer(compressedFile);
    } catch (error) {
      console.log(error);
    }
  };

  const uploadToServer = async (file) => {
    const formData = new FormData();

    formData.append("image", file);

    const { data, error } = await supabase.auth.getSession();
    const session = data.session;

    try {
      onProgressStart("Upload");
      const response = await fetch(
        `${process.env.REACT_APP_IMAGE_URL}/upload`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        }
      );

      if (!response.ok) {
        onError(response.statusText);
        throw new Error("Upload failed");
      }

      const result = await response.json();
      onSuccess("Upload", result);
    } catch (error) {
      onError("Upload", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file);
    }
  };

  const handleUpdate = async () => {
    setLoading(true);

    let updatedProduct = { ...product, created_at: new Date() };

    if (
      updatedProduct.brand_categ === null &&
      updatedProduct.has_brand_category
    ) {
      updatedProduct.brand_categ = subBrands[updatedProduct.category][0];
    } else {
      console.log("Brand Category", updatedProduct.brand_categ);
    }

    if (category && category.price_map_required) {
      for (let size of selectedSizes) {
        if (Number(priceMap[size]) > Number(prevMap[size])) {
          console.log(priceMap[size], prevMap[size]);
          console.log(typeof priceMap[size], typeof prevMap[size]);

          toast.error(
            `New price for size ${size} cannot be greater than old price`
          );
          setLoading(false);
          return;
        }
      }

      if (Object.keys(priceMap).length === 0 && selectedSizes.length > 0) {
        toast.error("Please enter prices for all selected sizes");
        setLoading(false);
        return;
      }

      console.log("Perfumes", selectedSizes);
      updatedProduct.price = null;
      updatedProduct.priceMap = priceMap;
      updatedProduct.prevMap = prevMap;
    }

    if (category && category.cod_price_map_required) {
      console.log(stockMap, codPriceMap);
      if (Object.keys(codPriceMap).length === 0 && selectedSizes.length > 0) {
        toast.error("Please enter COD prices for all selected sizes");
        setLoading(false);
        return;
      }

      updatedProduct.cod_price = null;
      updatedProduct.codPriceMap = codPriceMap;
    }

    if (category && category.stock_map_required) {
      console.log(stockMap);
      if (Object.keys(stockMap).length === 0 && selectedSizes.length > 0) {
        toast.error("Please enter stocks for all selected sizes");
        setLoading(false);
        return;
      }

      updatedProduct.stockMap = stockMap;
      updatedProduct.stock = null;
    }

    console.log("Updated Product", updatedProduct);

    const { error } = await supabase
      .from("products")
      .update(updatedProduct)
      .eq("id", productId);

    if (error) {
      setLoading(false);
      console.error("Error updating product: ", error);
      toast.error("Error updating product");
    } else {
      setLoading(false);
      console.log(updatedProduct);
      toast.success("Product updated successfully");
      // Navigate to another page or show a success message
    }
  };
  if (!product) return null;

  return (
    <div className="page flex flex-col items-center  bg-gray-100 overflow-y-scroll">
      <TopBar />

      <div className="bg-white rounded-lg shadow p-2 m-4 w-full max-w-xl">
        <h1 className="mb-4 text-xl font-semibold">Edit Product</h1>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={product.name}
            onChange={(e) => setProduct({ ...product, name: e.target.value })}
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="category"
          >
            Category
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="category"
            value={product.category}
            onChange={(e) =>
              setProduct({ ...product, category: e.target.value })
            }
            required
          >
            {categories.map((category, index) => (
              <option key={index} value={category.category}>
                {category.category}
              </option>
            ))}
          </select>
        </div>
        {category &&
        ((category.sizes?.length > 0 && category.price_map_required) ||
          (category.sizes?.length > 0 && category.stock_map_required)) ? (
          <div className="mb-4">
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="size"
              >
                Sizes
              </label>
              <div className="flex flex-col justify-center align-middle items-center overflow-x-auto flex-wrap">
                {category &&
                  category.sizes.map((size, index) => (
                    <div key={index} className="items-center m-2 flex flex-row">
                      <span
                        style={{
                          boxShadow: selectedSizes.includes(size)
                            ? null
                            : "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                          fontSize: "0.8em",
                        }}
                        className={`inline-block w-10 h-10  flex items-center justify-center text-center rounded-full cursor-pointer ${
                          selectedSizes.includes(size)
                            ? "bg-[#ff0054] text-white font-bold"
                            : "bg-gray-500"
                        }`}
                        onClick={() => handleSizeClick(size)}
                        disabled={
                          !product.available_sizes ||
                          !product.available_sizes.includes(size)
                        } // Disable the size if it's not in product.available_sizes
                      >
                        {size}
                      </span>

                      {category && category.price_map_required && (
                        <>
                          <input
                            type="number"
                            placeholder={`Old ${size}`}
                            className={`ml-2 w-36 h-10  rounded-md border-gray-300 border-2 p-1
                              ${
                                !product.available_sizes ||
                                !product.available_sizes.includes(size)
                                  ? "bg-gray-200"
                                  : ""
                              }
                              `}
                            onChange={(event) =>
                              handlePrevPriceChange(size, event)
                            }
                            value={prevMap[size]}
                            disabled={
                              !product.available_sizes ||
                              !product.available_sizes.includes(size)
                            } // Disable the size if it's not in product.available_sizes
                          />

                          <input
                            type="number"
                            placeholder={`New ${size}`}
                            className={`ml-2 w-36 h-10  rounded-md border-gray-300 border-2 p-1
                              ${
                                !product.available_sizes ||
                                !product.available_sizes.includes(size)
                                  ? "bg-gray-200"
                                  : ""
                              }
                              `}
                            onChange={(event) => handlePriceChange(size, event)}
                            value={priceMap[size]}
                            disabled={
                              !product.available_sizes ||
                              !product.available_sizes.includes(size)
                            } // Disable the size if it's not in product.available_sizes
                          />
                        </>
                      )}

                      {category && category.stock_map_required && (
                        <>
                          <input
                            type="number"
                            placeholder={`Stocks of size ${size}`}
                            className={`ml-5 w-40 h-10  rounded-md border-gray-300 border-2 p-1
                              ${
                                !product.available_sizes ||
                                !product.available_sizes.includes(size)
                                  ? "bg-gray-200"
                                  : ""
                              }
                              `}
                            onChange={(event) => handleStockChange(size, event)}
                            value={stockMap[size]}
                            disabled={
                              !product.available_sizes ||
                              !product.available_sizes.includes(size)
                            } // Disable the size if it's not in product.available_sizes
                          />
                        </>
                      )}
                      {category && category.cod_price_map_required && (
                        <>
                          <input
                            type="number"
                            placeholder={`COD Price of size ${size}`}
                            className={`ml-5 w-40 h-10  rounded-md border-gray-300 border-2 p-1
                              ${
                                !product.available_sizes ||
                                !product.available_sizes.includes(size)
                                  ? "bg-gray-200"
                                  : ""
                              }
                              `}
                            onChange={(event) =>
                              handleCodPriceChange(size, event)
                            }
                            value={codPriceMap[size]}
                            disabled={
                              !product.available_sizes ||
                              !product.available_sizes.includes(size)
                            } // Disable the size if it's not in product.available_sizes
                          />
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <>
            {category && category.sizes?.length > 0 && (
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="size"
                >
                  Sizes
                </label>
                <div className="flex flex-row justify-center align-middle items-center overflow-x-auto flex-wrap">
                  {categories
                    .find((category) => category.category === product.category)
                    .sizes.map((size, index) => (
                      <div key={index} className="items-center m-2">
                        <span
                          style={{
                            boxShadow: selectedSizes.includes(size)
                              ? null
                              : "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                            fontSize: "0.8em",
                          }}
                          className={`inline-block w-10 h-10  flex items-center justify-center text-center rounded-full cursor-pointer ${
                            selectedSizes.includes(size)
                              ? "bg-[#ff0054] text-white font-bold"
                              : "bg-gray-500"
                          }`}
                          onClick={() => handleSizeClick(size)}
                          disabled={
                            !product.available_sizes ||
                            !product.available_sizes.includes(size)
                          } // Disable the size if it's not in product.available_sizes
                        >
                          {size}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </>
        )}

        {category && !category.price_map_required && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="price"
              >
                Price
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="price"
                type="number"
                value={product.price}
                onChange={(e) =>
                  setProduct({ ...product, price: e.target.value })
                }
                required
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="prev_price"
              >
                Previous Price
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="prev_price"
                type="number"
                value={product.prev_price}
                onChange={(e) =>
                  setProduct({ ...product, prev_price: e.target.value })
                }
                required
              />
            </div>
          </>
        )}
        {subBrands[product.category] &&
          subBrands[product.category].length > 0 && (
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="name"
              >
                Brand / Company
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="brand_categ"
                value={product.brand_categ}
                onChange={(e) =>
                  setProduct({ ...product, brand_categ: e.target.value })
                }
                required
              >
                {subBrands[product.category].map((brand, index) => (
                  <option key={index} value={brand}>
                    {brand}
                  </option>
                ))}
              </select>
            </div>
          )}
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            Label
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            value={product.brand}
            onChange={(e) => setProduct({ ...product, brand: e.target.value })}
          />
        </div>

        {category && !category.cod_price_map_required && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="cod_price"
            >
              Cash on Delivery Price (Optional)
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="cod_price"
              type="number"
              value={product.cod_price}
              onChange={(e) => {
                console.log(Number(e.target.value));
                setProduct({ ...product, cod_price: Number(e.target.value) });
              }}
              required
            />
          </div>
        )}

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            Description
          </label>
          <textarea
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="description"
            value={product.description}
            onChange={(e) =>
              setProduct({ ...product, description: e.target.value })
            }
            placeholder="Description is required"
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="avg_rating"
          >
            Average Rating
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="avg_rating"
            type="number"
            value={product.avg_rating}
            onChange={handleRatingChange}
            required
          />
          {error && <p className="text-red-500">{error}</p>}
        </div>
        {category && !category.stock_map_required && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="stock"
            >
              Stock
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="stock"
              type="number"
              value={product.stock}
              onChange={(e) =>
                setProduct({ ...product, stock: e.target.value })
              }
              required
            />
          </div>
        )}

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="images"
          >
            Images / Videos
          </label>
          {product.images.map((image, index) => (
            <div key={index} className="relative">
              <img
                src={image}
                alt={`Product ${index}`}
                className="w-16 h-16 mb-4"
              />
              <MdDeleteForever
                className="absolute top-0 right-0 text-red-500 cursor-pointer"
                size={24}
                onClick={() => handleDeleteImage(index)}
              />
            </div>
          ))}
          {/* <IKUpload
              fileName={product.name}
              folder={
                (
                  categories.find(
                    (category) => category.category === product.category
                  ) || {}
                ).image_folder || "default_folder"
              }
              onError={onError}
              onSuccess={onSuccess}
              onUploadStart={onUploadStart}
              isPrivateFile={false}
              style={{
                display: "none",
              }}
              ref={ikUploadRefTest}
              accept="image/*"
            /> */}

          <div
            className="flex justify-center items-center border-2 border-dashed rounded w-16 h-16 text-gray-700 cursor-pointer"
            onClick={handleClick}
          >
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
              accept="image/*"
            />

            <FiPlus size={24} />
          </div>
        </div>
        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleUpdate}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "white",
              }}
              size={25}
            />
          ) : (
            "Update"
          )}
        </button>
        <button
          className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={handleDelete}
          disabled={loading}
          style={{ marginTop: "1rem" }}
        >
          {loading ? (
            <CircularProgress
              style={{
                color: "white",
              }}
              size={25}
            />
          ) : (
            "Delete"
          )}
        </button>
      </div>
    </div>
  );
}
