import React, { useState, useEffect, useContext } from "react";
import { SessionContext } from "../../components/SessionContext";
import TopBar from "../../components/TopBar";
import { useNavigate } from "react-router-dom";
import supabase from "../../supabase";
import moment from "moment";

const Orders = () => {
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [status, setStatus] = useState("waiting");

  useEffect(() => {
    const fetchOrders = async () => {
      const { data, error } = await supabase
        .from("orders")
        .select("*")
        .eq("order_status", status)
        .order("created_at", { ascending: false }); // Order by creation date, newest first

      if (error) {
        console.error("Error fetching orders: ", error);
      } else {
        const filteredData = data.filter(
          (order) =>
            (order.payment_method === "Razorpay" &&
              order.razorpay_payment_id) ||
            order.payment_method === "COD"
        );
        setOrders(filteredData);
      }
    };

    fetchOrders();
    const intervalId = setInterval(fetchOrders, 300000); // Call every 5 minutes
    return () => clearInterval(intervalId);
  }, [status]);

  return (
    <div className="page h-screen overflow-y-scroll pb-10">
      <TopBar avatarInfo={session?.user.user_metadata} />

      <div className="flex justify-center my-4">
        <button
          className={`mx-2 py-2 px-4 ${
            status === "waiting"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("waiting")}
        >
          Waiting
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "shipped"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("shipped")}
        >
          Shipped
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "delivered"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("delivered")}
        >
          Delivered
        </button>
        <button
          className={`mx-2 py-2 px-4 ${
            status === "cancelled"
              ? "bg-blue-500 text-white"
              : "bg-white text-blue-500"
          } rounded`}
          onClick={() => setStatus("cancelled")}
        >
          Cancelled
        </button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
        {orders.map((order) => (
          <div
            key={order.order_id}
            className="p-4 border rounded cursor-pointer shadow-md bg-white"
            onClick={() => navigate(`/order/${order.order_id}`)}
          >
            <div className="text-center font-semibold text-lg mb-2 text-blue-700">
              {order.items.map((item) => item.name).join(", ")}
            </div>

            <div className="text-center text-sm mb-2 text-gray-600">
              <span
                className="font-bold"
                style={{
                  color: order.payment_method === "COD" ? "red" : "green",
                  fontWeight: "bold",
                }}
              >
                {order.payment_method === "COD" ? "CASH ON DELIVERY" : "ONLINE"}
              </span>
            </div>

            <div className="text-center text-sm mb-2 text-gray-600">
              Ordered At:{" "}
              {moment(order.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </div>
            <div className="text-center text-sm mb-2 text-gray-600">
              Amount: <span className="font-bold">₹{order.amount}</span>
            </div>

            <div className="flex justify-center flex-wrap">
              {order.items.map((item) => (
                <div key={item.id} className="m-2">
                  <img
                    src={item.images[0]}
                    alt={item.name}
                    className="w-16 h-16 object-cover rounded"
                  />
                  <div className="text-center text-xs text-gray-500">
                    {item.name}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
